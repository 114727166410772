.accordion {
    width: 100% !important;
    margin-left: 0 !important;
}

.head {
    margin-top: 0;
}

.field_box {
    min-height: 55px;
    margin-bottom: 0;
}

.field_box .MuiInput-formControl{
    width: 100%;
    

}

.field_box .MuiInput-underline:before{
    border-bottom: 1px solid #D3D8DA;
}

.field_box .MuiFormControl-root {
    margin: 0 !important;
    width: 100%;
    max-width: initial;
    min-width: initial;
    display: block;
}

.form_button {
    margin-top: 18px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
}

.backBtnFrom button {
    width: 89px !important;
    height: 36px !important;
    border: 1px solid #e8e9e9;
    border-radius: 18px !important;
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    padding: 0;
    align-self: center;
    position: absolute;
    left: 70px;
    background-color: transparent;
    box-shadow: none;
    margin-top: 30px !important;
    min-width: initial !important;
    padding-right: 5px;
}

.backBtnFrom button:hover{
    border: 1px solid #e8e9e9;
    background-color: transparent;
    box-shadow: none;

}

.backBtnFrom button span {
    font-size: 14px;
    letter-spacing: 1.26px;
    color: #4d4d4d;
}

.title_top {
    text-align: center;
    font-family: "Roboto";
    font-size: 28px;
    color: #4d4d4d;
    text-transform: capitalize;
    font-weight: 300;
    margin: 0 0 15px!important;
    border-bottom: #D3D8DA dashed 1.5px;
    padding-bottom: 30px;
}

.border_bottom{
    border-bottom: #D3D8DA 1px solid;
}

.border_top_bottm {
    border-top: 1px solid #d3d8da;
    border-bottom: 1px solid #d3d8da;
    padding: 15px 0;
}

.userRollBox{
    display: block;
    width: 100%;
    padding: 0 20px;
}

.sub_title{
    font-size: 22px;
    letter-spacing: 0;
    color: #4d4d4d;
    text-transform: capitalize;
    opacity: .8;
    font-weight: 300;
    text-align: left;
    margin: 0!important;
}

 .chip_list {
    width: 100% !important;
}

.opt_container .MuiPaper-outlined {
    border: none !important;
}

.orgTitle span {
    margin-left: 0px;
}

.text-editor .text-box p {
    color: #4d4d4d !important;
}

.text-editor .text-box {
color: #4d4d4d !important;
}

.errorMessage {
    position: absolute;
    top: -68px;
    z-index: 99999;
    max-width: 760px;
    left: 69px;
    width: 100%;
}

.ondisableText{
    font-weight: 100;
    color: #818e94;
  }

  .react-tel-input .selected-flag {
    outline: none;
    position: relative;
    width: 38px;
    height: 100%;
    padding: 0 0 0 0 !important;
    border-radius: 3px 0 0 3px;
}

.middle-section-white{
    padding-bottom: 30px;
}

.accordionContainerD .MuiCollapse-container {
    width: 93%;
    margin-left: 40px;
    position: relative;
    top: 0;
    background-color: transparent;
}

.header-nav{
    min-height: auto !important;
}

.middle-section {
    margin-top: 77px !important;
}

.DetailsHeaderContainer .DtlInfoCol .DtlInfoColSubTitle {
    text-transform: capitalize;
}

.header_alert {
    z-index: 9999 !important;
    width: 60% !important;
    position: fixed !important;
    top: 10px !important;
    margin-left: 68px !important;
}

.MuiExpansionPanelSummary-content{
    cursor: pointer;
}